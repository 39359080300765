import { FC, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  ColorProps,
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  LinkColorType,
  ThemeProps,
  WithThemeProps,
} from '@core/type';
import { getLinkColorProps, getPaletteHandlers } from '@core/util';

import { Icon } from '../icon';
import { Box } from '../layout';
import { Typography } from '../typography';
import { InputCheckboxProps } from './interface-input';

export const InputCheckbox = ({
  disabled,
  field,
  fieldState,
  isError: defaultIsError,
  shouldShowError,
  name,
  label,
  isSubmitted,
  children,
  value,
  onClick,
  withStyles,
}: InputCheckboxProps) => {
  const isError = fieldState
    ? (isSubmitted || fieldState.isTouched) && !!fieldState.error
    : !!defaultIsError;

  const shouldErrorBeShown = shouldShowError ?? isError;

  const {
    typography: { checkbox },
  } = useTheme() as ThemeProps;

  const backgroundColor: EColor = disabled ? EColor.NEUTRAL : EColor.ACCENT;

  return (
    <Box
      display="inline-flex"
      gap="8px"
      position="relative"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      alignItems="center"
      onClick={() => {
        if (!disabled) {
          field?.onChange?.(!field.value);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          onClick?.(!field?.value || value);
        }
      }}
    >
      <Box
        position="absolute"
        minWidth="16px"
        top="4px"
        height="16px"
        borderWidth="1px"
        borderStyle="solid"
        borderRadius="4px"
        aria-label={name}
        aria-checked={field?.value || value || false}
        aria-disabled={disabled}
        borderColor={getBorderColor(disabled, shouldErrorBeShown, field?.value)}
        backgroundColor={field?.value || value ? backgroundColor : null}
        display="flex"
      >
        {field?.value || value ? (
          <Icon
            type={EIcon.CHECK}
            color={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
            strokeWidth="2"
            right="0.2px"
            top="-0.8px"
            position="absolute"
          />
        ) : (
          <></>
        )}
      </Box>
      {children ? (
        <StyledSpan color={getTextStateColor(disabled, shouldErrorBeShown)} withStyles={withStyles}>
          {children}
        </StyledSpan>
      ) : (
        <Box marginLeft="32px">
          <Typography
            as={EActionAttribute.SPAN}
            color={getTextStateColor(disabled, shouldErrorBeShown)}
            fontSize={checkbox.fontSize}
            fontWeight={EFontWeight.REGULAR}
            lineHeight={checkbox.lineHeight}
            wordBreak="break-word"
          >
            {label}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const getBorderColor = (disabled: boolean, isError: boolean, isActive: boolean): ColorProps => {
  if (disabled) {
    return EColor.NEUTRAL;
  }
  if (isError) {
    return EColor.DANGER;
  }
  if (isActive) {
    return EColor.SECONDARY;
  }

  return EColor.PRIMARY;
};

const getTextStateColor = (disabled: boolean, isError: boolean): ColorProps => {
  if (disabled) {
    return EColor.NEUTRAL;
  }
  if (isError) {
    return EColor.DANGER;
  }

  return EColor.PRIMARY;
};

const getLinkColorFromTextColor = (color: ColorProps) => {
  if (color === EColor.PRIMARY) {
    return EColor.ACCENT;
  } else {
    return color;
  }
};

const StyledSpan: FC<{ color: ColorProps; children: ReactNode; withStyles: boolean }> = styled.span(
  ({
    theme: {
      palette,
      typography: { checkbox },
    },
    color,
    fontSize,
    withStyles,
  }: {
    color: ColorProps;
    fontSize?: string;
    withStyles: boolean;
  } & WithThemeProps) => {
    const { getTextColor } = getPaletteHandlers(palette);
    const { color: linkColor } = getLinkColorProps({
      color: getLinkColorFromTextColor(color) as LinkColorType,
      palette,
    });

    return `
    display: block;
    margin-left: 32px;

    ${
      withStyles &&
      `* {
        font-size: ${fontSize ?? checkbox.fontSize};
        font-weight: ${checkbox.fontWeight};
        line-height: ${checkbox.lineHeight}
      }

      span {
        color: ${getTextColor(color)};
      }

      a {
        transition: all 0.2s ease-out 0.2s, color 0s;
        color: ${linkColor};

        &:hover,
        &:visited {
          color: ${linkColor};
        }
      }`
    }
  `;
  },
);
