import {
  CountryCode,
  NumberFormat,
  ParsedNumber,
  formatNumber,
  getCountryCallingCode,
  isPossibleNumber,
  parseNumber,
} from 'libphonenumber-js';

import { ECountry, ECountryPhoneCode } from '@core/type';

const formatPhoneNumber = (country: ECountry, format: NumberFormat, number: string): string => {
  if (!number) {
    return null;
  }

  // Some countries are not supported by library
  try {
    if (isPossibleNumber(number, country as CountryCode)) {
      const parsedNumber = parseNumber(number, country as CountryCode);

      if (Object.keys(parsedNumber).length > 0) {
        return formatNumber(parsedNumber as ParsedNumber, format);
      }
    }
  } catch (e) {
    // TODO[GARB-6494]: add phone format for not supported countries
    return number;
  }
  return null;
};

export const getInternationalPrefix = (country: ECountry) => {
  // Some countries are not supported by library
  try {
    return Number(getCountryCallingCode(country as CountryCode));
  } catch (e) {
    if (country in ECountryPhoneCode) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return ECountryPhoneCode[country];
    }
    return null;
  }
};

export const formatPhoneNumberNational = (locale: ECountry, number: string): string => {
  return formatPhoneNumber(locale, 'NATIONAL', number);
};

export const formatPhoneNumberInternational = (locale: ECountry, number: string): string => {
  return formatPhoneNumber(locale, 'INTERNATIONAL', number);
};
